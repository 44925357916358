// $utilities: (
//   "width": (
//     property: width,
//     class: w,
//     values: (
//       25: 25%,
//       33: 33%,
//       50: 50%,
//       66: 66%,
//       75: 75%,
//       100: 100%,
//       auto: auto,
//     ),
//   ),
// );

@import "~bootstrap/scss/bootstrap";

.checkbox-row {
  // width:105px !important;
  // float: left;
  padding: 1px;
  margin: 1px;
  //background-color: #f6f1f1;
}

.checkbox-row:hover {
  background-color: #f5f5f5;
}

Label.label-heading {
  font-weight: 700;
  border-bottom: 1px solid #ff9900;
  margin-top: 15px;
  //display: block;
}

Label.label-sub-heading {
  font-weight: 600;
  border-bottom: 1px solid #ff9900;
  margin-top: 10px;
  //display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.w-33 {
  width: 33% !important;
}

.w-66 {
  width: 66% !important;
}

.w-67 {
  width: 67% !important;
}

.overflow-image {
  position: relative;
    overflow: hidden;

  img {
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
}